/* FilterPanelBlock */
.filterpanel__block.collapsible {
    padding-bottom: 0;
    height: auto;
}
.collapsible .filterpanel__subtitle {
    padding-right: 2.3rem;
}
.collapsible .filterpanel__subtitle span {
    margin-right: 0.75rem;
}
.filterpanel__block.collapsible.opened {
    padding-bottom: 2px;
}
/* Tooltip */
/* general */
.rc-tooltip-placement-top .rc-tooltip-arrow {
    left: 50%;
}
.rc-tooltip-placement-top .rc-tooltip-inner {
    margin-left: 0px;
}

/* for filter panel */
.filterpanel-tooltip .rc-tooltip-content {
    margin-left: -20px;
}
.filterpanel-tooltip .rc-tooltip-arrow {
    left: 5%;
}
/* for product image */
.product-img-tooltip .rc-tooltip-content {
    margin-left: -20px;
}
.product-img-tooltip .rc-tooltip-arrow {
    left: 5%;
}

/* RangeSlider */
.range-slider-box .range-slider-box__label {
    padding: 25px 0px 15px 0px;
}
.rc-slider-mark {
    top: -25px;
    font-size: 14px;
}
.rc-slider-mark .rc-slider-mark-text:first-child {
    width: 50% ! important;
    text-align: left;
    margin-left: 0 ! important;
}
.rc-slider-mark .rc-slider-mark-text:last-child {
    width: 50% ! important;
    text-align: right;
    margin-left: -50% ! important;
}

.rc-slider-rail {
    width: 100%;
    height: 0.5rem;
    border-radius: 0.25rem;
    background: rgba(0, 25, 95, 0.1);
    padding: 0 5px;
}
.rc-slider-track {
    height: 0.5rem;
    border-radius: 0.25rem;
    background-color: rgba(0, 25, 95, 0.15);
}
.rc-slider-step {
    padding: 0 10px;
    height: 0.5rem;
}
.rc-slider-dot {
    width: 0;
    height: 0.5rem;
    border: 0;
    background-color: #fff;
    cursor: pointer;
    border-radius: 0;
    margin: 0 -4px;
    bottom: 0;
}
.rc-slider-handle {
    border-color: transparent;
    border: 0;
    background: transparent;
    margin: 0;
}
.rc-slider-handle:focus, .rc-slider-handle:active {
    box-shadow: none;
    border-color: transparent;
}
.rc-slider-handle:before {
    position: absolute;
    top: -1px;
    left: -3px;
    content: '';
    border-radius: 50%;
    height: 0.5rem;
    width: 0.5rem;
    background: #e32236;
    z-index: 4;
}
.rc-slider-handle:after {
    position: absolute;
    top: -8px;
    left: -10px;
    width: 1.375rem;
    height: 1.375rem;
    border-radius: 50%;
    background: white;
    border: 2px solid rgba(0, 25, 95, 0.15);
    z-index: 3;
    content: '';
}

/* Slider ToolTip */
.rangeSliderStyle, .prefixCls {
    z-index: 599;
}

/*
.treeview-2-level__item.has-child:after {
    content: '';
    width: 0.125rem;
    height: calc(100% - 40px);
    position: absolute;
    left: -1.25rem;
    top: 1.375rem;
    background-color: #d9dde7;
}
*/

.autocomplete__counter {
    padding: 0.5rem 0.75rem;
}

.product__comment-text, .product-comment__post{
    white-space: pre-line;
}

.linecard__img-box > img.producer-teaser__img  {
    padding-top: 30px;
}
.linecard__img-box > img.producer-teaser__img:first-child {
    padding-top: 0px;
}

.transaction-log {

}

pre {
    background:lightgrey;
    padding:15px;
    white-space: pre-wrap;
}

.shortened {
    padding: 0;
    margin: 0;
}

.no-margin {
    margin: 0;
}
